import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead

const CourseCategory1 = ({ onAddToCart }) => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Simulate fetching product (course) data
    setProducts([
      { id: 1, name: "Computer Hardware", price: 500, image: "assets/images/courses/4by3/HW.png" },
      { id: 2, name: "Computer Networking", price: 800, image: "assets/images/courses/4by3/NW.png" },
      { id: 3, name: "AWS Cloud Computing", price: 1200, image: "assets/images/courses/4by3/AWS.png" },
      { id: 4, name: "Linux", price: 1500, image: "assets/images/courses/4by3/Linux.png" },
      { id: 5, name: "CCNA", price: 1500, image: "assets/images/courses/4by3/CCNA.png" },
      { id: 6, name: "MCITP", price: 1500, image: "assets/images/courses/4by3/MCT.png" },
      { id: 7, name: "MCSE", price: 1500, image: "assets/images/courses/4by3/Linux.png" }
    ]);
  }, []);

  const handleAddToCart = (product) => {
    onAddToCart(product); // Add to cart functionality
  };

  const handleNavigateToCourseList = () => {
    navigate('/courselist'); // Navigate to course list using navigate
  };

  return (
    <div>
      <br /><br /><br />
      <section className="bg-light position-relative">
        <div className="container position-relative">
          <div className="row">
            <div className="col-12">
              <div className="row align-items-center">
                <div className="col-6 col-md-3 text-center order-1">
                  <img src="assets/images/element/cat1.png" alt="Category" />
                </div>
                <div className="col-md-6 px-md-5 text-center position-relative order-md-2 mb-5 mb-md-0">
                  <h1 className="mb-3">What do you want to learn?</h1>
                  <p className="mb-3">Grow your skill with the most reliable online courses and certifications</p>
                  <form className="bg-body rounded p-2">
                    <input className="form-control border-0 me-1" type="search" placeholder="Search course " />
                    <button type="button" className="btn btn-dark rounded">Search</button>
                  </form>
                </div>
                <div className="col-6 col-md-3 text-center order-3">
                  <img src="assets/images/element/cat2.png" alt="Cat" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Dynamic Course Listing */}
      <section>
        <div className="container">
          <div className="row g-4">
            {products.map((product) => (
              <div key={product.id} className="col-sm-6 col-md-4 col-xl-3">
                <div className="card card-body text-center position-relative btn-transition p-4">
                  <div className="col-md-12">
                    <img 
                      src={product.image} 
                      alt={product.name} 
                      className="img-border" 
                      style={{ cursor: 'pointer' }} // Set cursor to pointer for image
                      onClick={handleNavigateToCourseList} // Redirect on image click
                    />
                  </div>
                  <h5 
                    className="mb-2 mt-3" 
                    style={{ cursor: 'pointer' }} // Set cursor to pointer for heading
                    onClick={handleNavigateToCourseList} // Redirect on heading click
                  >
                    {product.name}
                  </h5>
                  <h6 className="mb-0">Price: ₹{product.price}</h6>
                  <button 
                    className="btn btn-primary mt-3" 
                    onClick={() => handleAddToCart(product)}
                    style={{ cursor: 'pointer' }} // Set cursor to pointer for button
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CourseCategory1;